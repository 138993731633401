<template>
  <v-dialog
    v-if="veiculo"
    :key="atualizarComponente"
    :value="mostrar"
    content-class="dialog-detalhes-veiculo"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar color="grey lighten-3" flat dense>
        <v-toolbar-title> Detalhes veículo</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn text small @click="$emit('fechar')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <h1 class="headline font-weight-black">
          {{ veiculo.placa_veiculo }}
        </h1>
        <v-divider class="mb-3"/>

        <template v-if="veiculo.veiculo">
          <v-row>
            <v-col cols="12" lg="6">
              <p class="ma-0">
                <b>Tipo: </b>
                {{ veiculo.veiculo.tipo_veiculo.descricao || "-" }}
              </p>
              <p class="ma-0">
                <b>Marca: </b> {{ veiculo.veiculo.veiculo_modelo.veiculo_marca.descricao || "-" }}
              </p>
              <p class="ma-0"><b>Cor: </b> {{ veiculo.veiculo.cor.nome_portugues || "-" }}</p>
              <p class="ma-0">
                <b>Ano: </b> {{ veiculo.veiculo.ano_fabricacao || "-" }}
              </p>
              <p class="ma-0">
                <b>Validade alerta: </b> {{ validadeAlertaAtual || "-" }}
              </p>
              <p class="ma-0">
                <b>Modelo: </b> {{ veiculo.veiculo.veiculo_modelo.descricao || "-" }}
              </p>
              <p class="ma-0 diasSemana">
                <v-card
                  outlined
                  v-for="item in $store.state.layout.diasDaSemana"
                  :key="item.id"
                  class="d-flex align-center ma-1"
                >
                  <v-checkbox
                    v-model="diasDaSemanaSelecionados"
                    :label="item.descricao"
                    :value="item.id"
                    class="d-flex align-center ma-0"
                    hide-details="auto"
                    readonly
                  />
                </v-card>
              </p>
              <!--p class="mt-2">
                <v-btn outlined small color="success" @click="adicionar">
                  <v-icon left v-text="'mdi-plus'" />
                  Editar alerta
                </v-btn>
                <dialog-editar-veiculo
                  :mostrar="mostrarDialogEditarVeiculo"
                  :diasSemanaEditar="diasSemanaEditar"
                  :validadeAlerta="validadeAlerta"
                  :idVeiculoEditar="idVeiculoEditar"
                  :formatoIsoDataSemHora="formatoIsoDataSemHora"
                  @fechar="mostrarDialogEditarVeiculo = false"
                  @salvou="salvouVeiculo"
                />
              </p-->
            </v-col>
            <v-col cols="12" lg="6">
              <p class="ma-0">
                <v-tooltip bottom :open-delay="200">
                  <template v-slot:activator="{ on, attrs }">
                    <b v-bind="attrs" v-on="on">Cidade: </b>
                  </template>
                  <span>Cidade de emplacamento do veículo</span>
                </v-tooltip>
                {{
                  veiculo.veiculo.cidade
                    ? veiculo.veiculo.cidade.descricao
                    : "-"
                }}
              </p>
              <p class="ma-0">
                <v-tooltip :open-delay="200" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <b v-bind="attrs" v-on="on">CPF: </b>
                  </template>
                  <span>CPF do dono do veículo</span>
                </v-tooltip>
                {{ veiculo.veiculo.pessoa_relacionada_cpf || "-" | cpfCnpj }}
              </p>
              <p class="ma-0">
                <b>Chassi: </b> {{ veiculo.veiculo.chassi || "-" }}
              </p>
              <p class="ma-0">
                <b>Renavam: </b> {{ veiculo.veiculo.renavam || "-" }}
              </p>
            </v-col>
          </v-row>
        </template>

        <template v-else>
          <i class="body-2"> Informações do cortex não disponíveis </i>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import formatarData from "@/utils/formatar-data-input";

export default {
  components: {
    DialogEditarVeiculo: () =>
      import("@/components/dialog/DialogEditarVeiculo"),
  },

  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    veiculo: {
      type: Object,
      default: () => {
      },
    },
    diasSemana: {
      type: Array,
      default: () => [],
    },
    formatoIsoData: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mostrarDialogDetalhesVeiculo: false,
      mostrarDialogEditarVeiculo: false,
      carregandoBuscarVeiculos: false,
      carregandoRemoverVeiculo: false,
      veiculoDetalhes: this.veiculo,
      diasSemanaEditar: null,
      validadeAlerta: null,
      formatoIsoDataSemHora: null,
      diasDaSemanaSelecionados: null,
      idVeiculoEditar: null,
      validadeAlertaAtual: null,
      estadoCompomente: null,
      atualizarComponente: 0,
      atualizacaoDiasDaSemana: null,
    };
  },

  watch: {
    veiculo: {
      handler(data) {
        this.validadeAlertaAtual = formatarData.ptBrSemHorario(
          data.validade_alerta
        );
      },
    },
    diasSemana: {
      handler(data) {
        this.diasDaSemanaSelecionados = data;
      },
    },
  },

  methods: {
    async adicionar() {
      this.diasSemanaEditar = this.diasDaSemanaSelecionados;
      this.formatoIsoDataSemHora = this.formatoIsoData;
      this.validadeAlerta = this.veiculo.validade_alerta;
      this.idVeiculoEditar = this.veiculo.id;
      this.mostrarDialogEditarVeiculo = true;
    },
    async salvouVeiculo(diaSemanaAtualizado, validadeAlertaAtualizado) {
      this.mostrarDialogEditarVeiculo = false;

      const array = [];
      array.push(diaSemanaAtualizado.split(",").map(Number));
      array.map((item) => {
        this.atualizacaoDiasDaSemana = item;
      });

      this.validadeAlertaAtual = formatarData.ptBrSemHorario(
        validadeAlertaAtualizado
      );

      this.diasDaSemanaSelecionados = this.atualizacaoDiasDaSemana;
      this.atualizarComponente += 1;
    },
  },
};
</script>

<style lang="css">
.diasSemana .v-input--selection-controls__input {
  margin-right: 5px !important;
}

.diasSemana .v-label.v-label {
  font-size: 13px;
}

.diasSemana label {
  margin: 0;
}

.diasSemana {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  right: 5px;
}

.dialog-detalhes-veiculo {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .dialog-detalhes-veiculo {
    width: 40% !important;
  }
}
</style>
